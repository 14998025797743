import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img
                          alt="Migraleve"
                          src="/sites/migraleve.co.uk/files/images/logo.jpg"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="navOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper1">
                <div id="block-menu-primary-links" className="block block-menu">
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first home">
                        <a href="/home" title="Home">
                          <img src="/sites/migraleve.co.uk/files/images/home.png" />
                        </a>
                      </li>
                      <li className="expanded aboutmigraines">
                        <a href="/about-migraine" title="About Migraines" className="about-migraine">
                          ABOUT MIGRAINES
                        </a>
                        <ul className="menu">
                          <li className="leaf first migrainesymptoms">
                            <a href="/migraine-symptoms" title="Migraine Types & Symptoms">
                              Migraine Types & Symptoms
                            </a>
                          </li>
                          <li className="leaf potentialmigrainecauses">
                            <a
                              href="/migraine-causes"
                              title="Potential Migraine Causes"
                            >
                              Potential Migraine Causes
                            </a>
                          </li>
                          <li className="leaf last whatcanyoudoaboutit">
                            <a
                              href="/migraine-treatment-and-prevention"
                              title="Treatment and Relief"
                            >
                              Treatment and Relief
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="leaf ourproducts">
                        <a
                          href="/migraine-relief-products"
                          title="Our Products"
                        >
                          OUR
                          <br /> PRODUCTS
                        </a>
                      </li>
                      <li className="leaf migrainemanagementtips">
                        <a
                          href="/migraine-tips"
                          title="Migraine Management Tips"
                        >
                          MIGRAINE MANAGEMENT TIPS
                        </a>
                      </li>
                      <li className="leaf migrainetriggerdiary">
                        <a
                          href="/migraine-trigger-diary"
                          title="Migraine Trigger Diary"
                        >
                          MIGRAINE TRIGGER DIARY
                        </a>
                      </li>
                      <li className="leaf last wheretobuy">
                        <a href="/where-to-buy" title="Where to buy">
                          WHERE TO BUY
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contentOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div className="homeBannerWrapper">
                  <div className="innerPageContentWrapper">
                    <div className="innerPageContent privacy-n-Legal">
                      <div id="node-5" className="node">
                        <div className="content clear-block">
                          <h1>Cookie Policy</h1>
                          <p>
                            This Cookie Policy is designed to tell you about our
                            practices regarding the collection of information
                            through cookies and other tracking technologies
                            (like gifs, web beacons, etc.).
                          </p>
                          <p>
                            The functionalities for which we use these
                            technologies may include the following:
                          </p>
                          <ul>
                            <li>Assisting you in navigation,</li>
                            <li>
                              Assisting in registering for our events, login,
                              and your ability to provide feedback,
                            </li>
                            <li>
                              Analyzing the use of our products, services or
                              applications,
                            </li>
                            <li>
                              Assisting with our promotional and marketing
                              efforts (including behavioral advertising),
                            </li>
                            <li>
                              Offering content of third parties (such as social
                              media content).
                            </li>
                          </ul>
                          <p>
                            Below is a detailed list of the cookies we use, with
                            a description. We classify cookies in the following
                            categories:
                          </p>
                          <ul>
                            <li>Strictly Necessary Cookies</li>
                            <li>Performance Cookies</li>
                            <li>Functional Cookies</li>
                            <li>Targeting Cookies</li>
                          </ul>
                          <p>
                            Except where allowed by applicable law, we place
                            cookies after having received your consent through
                            the cookie banner or preference center. You can
                            change your cookie settings per cookie category
                            (except for strictly necessary cookies that are
                            required for the proper functioning of the site) at
                            any time by clicking on the “cookie settings” button
                            below:
                          </p>
                          <p
                            style={{
                              "padding-bottom": "20px"
                            }}
                          >
                            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                              cookie settings
                            </button>
                          </p>
                          <div id="ot-sdk-cookie-policy">&nbsp;</div>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                          <h2>Links to other websites</h2>
                          <p>
                            This site may contain links or references to other
                            websites. Please be aware that we do not control the
                            cookies / tracking technologies of other websites
                            and this Cookie Policy does not apply to those
                            websites.
                          </p>
                          <h2>How to Contact Us</h2>
                          <p>
                            If you have any questions, comments, or concerns
                            about this Cookie Policy or the information
                            practices of this site, please use the contact
                            information included in our Privacy Policy.
                          </p>
                          <h2>Changes to This Cookie Policy</h2>
                          <p>
                            If this Cookie Policy changes, the revised policy
                            will be posted on this site. This Cookie Policy was
                            last updated 05 August 2021.
                          </p>
                        </div>
                        <div className="clear-block">
                          <div className="meta"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div className="footerContent">
                  <div
                    id="block-menu-menu-site-footer-menu"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="leaf first privacypolicy">
                          <a href="/privacy-policy" title="Privacy policy">
                            Privacy policy
                          </a>
                        </li>
                        <li className="leaf legalnotice">
                          <a href="/legal-notice" title="Legal notice">
                            Legal notice
                          </a>
                        </li>
                        <li className="leaf contactus">
                          <a href="/contact-us" title="Contact us">
                            Contact us
                          </a>
                        </li>
                        <li className="leaf active-trail cookiepolicy">
                          <a
                            href="/cookie-policy"
                            title="Cookie Policy"
                            className="active"
                          >
                            Cookie Policy
                          </a>
                        </li>
                        <li className="leaf modernslaveryactstatement">
                          <a
                            href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                            target="_blank"
                          >
                            Modern Slavery Act Statement
                          </a>
                        </li>
                        <li className="leaf last cookiesettings">
                          <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                            Cookie Settings
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="block-block-3" className="block block-block">
                    <div className="content">
                      <p>
                        <strong>
                          Always read the label. Ask your pharmacist for advice.
                        </strong>
                      </p>
                      <p>
                        MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                        codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                        codeine and buclizine. MIGRALEVE<em>™</em> Yellow
                        contains paracetamol and codeine.
                      </p>
                      <p>
                        Can cause addiction. For three days use only. This
                        medicine can make you feel sleepy. Do not drive while
                        taking this medicine until you know how it makes you
                        feel.
                      </p>
                      <p>
                        This site is published by Johnson &amp; Johnson Limited
                        which is solely responsible for its contents. It is
                        intended for a UK audience. MIGRALEVE<em>™</em> is a
                        registered trademark.
                      </p>
                      <p>
                        This site is best viewed with the following web
                        browsers: Internet Explorer version 8, Firefox version
                        2+, Safari 3+, Chrome version 3+.
                      </p>
                      <p>
                        © Johnson &amp; Johnson Limited 2024. This site was last
                        updated on: 15<sup>th </sup>August 2024
                      </p>
                    </div>
                  </div>
                  <p>
                    <a
                      id="popup-trigger"
                      href="/sites/migraleve.co.uk/files/pop-up-text"
                      rel="lightmodal"
                    >
                      hide this
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
